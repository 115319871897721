import React from 'react';
import Button from '@mui/material/Button';

const InstagramConnectButton = () => {
    const handleConnect = () => {
        const redirectUri = process.env.REACT_APP_BASE_URL + 'connect/ig/callback';
        const clientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
        const url = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=business_basic%2Cbusiness_manage_messages%2Cbusiness_manage_comments%2Cbusiness_content_publish`;
        window.location.href = url;
    };

    return (
        <Button variant="contained" color="primary" onClick={handleConnect}>
            Connect to Instagram
        </Button>
    );
};

export default InstagramConnectButton;
