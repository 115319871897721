import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [user, setUser] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
// get user details
  useEffect(() => {
    if (localStorage.getItem('token')) {
        const url = process.env.REACT_APP_API_BASE_URL + 'user/details/';
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setUser([data.user_info]);
            if (data.detail === 'Invalid token.'){
                logout();
            }
          } else {
            setErrorMessage(data[0]);
            console.error(data);
          }
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [token, '']);

  const login = useCallback((username, password, timezone) => {

      const url = process.env.REACT_APP_API_BASE_URL + 'user/login/';

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "email":username, username, password, timezone }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          // Call the setToken function to store the JWT
          localStorage.setItem('token', data.token);
          setToken(data.token);
          setIsAuthenticated(true);
          setUser([data.user_info]);
          // Redirect the user to the home page
        } else {
          setErrorMessage(data);
        }
      })
        .catch((error) => {
        // set the error message here

            setErrorMessage(error);
      });
  },[]);

  const logout = useCallback(() => {

      const url = process.env.REACT_APP_API_BASE_URL + 'logout/';

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then((response) => {
        if (!response.data) {
          // Call the setToken function to store the JWT
            setIsAuthenticated(false);
            setUser([]);
            setToken('');
            localStorage.removeItem('token');
        } else {
          setErrorMessage(response.data);
          console.error(response.data);
        }
      });

  },[token]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, logout, user, errorMessage, isLoading, setErrorMessage, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext)
};
