import React, {useEffect, useState} from 'react';
import DownloadButton from "polotno/toolbar/download-button";
import Button from "@mui/material/Button";
import {useStatus} from "../providers/MsgStatusProvider";
import {trackEvent} from "../services/analytics/fathom";
import {EditorEvents} from "../services/analytics/events";

export default function ActionControls({store, handleError, templateName, setTemplateName, setTemplateSaved}) {
    const [template, setTemplate] = useState([]);
    const [isAutoSave, setIsAutoSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const {setStatusMessage, setIsAlertOpen, setStatusType} = useStatus();

    const checkForVideo = (template) => {
        return template.pages.some(page =>
            page.children.some(child => child.type === 'video')
        );
    };

    const createNewPage = () => {
        // Clear all elements from the current page
        const allIds = store.activePage.children.map(el => el.id);
        store.deleteElements(allIds)

        setTemplateName('')

        // Clear the custom name in the store
        store.clear()
        store.activePage.set('custom', { ...store.activePage.custom, name: '' });
        setStatusType('success');
        setIsAlertOpen(true);
        setStatusMessage('Canvas cleared');
        setTemplateSaved(false);
    };

    const saveTemplate = async (isSave) => {
        const token = localStorage.getItem('token'); // retrieve authentication token from local storage
        if (store.toJSON().pages[0].custom?.name) {
            try {
                const url = process.env.REACT_APP_API_BASE_URL + 'templates/create/';
                const isVideo = checkForVideo(store.toJSON())
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`

                    },
                    body: JSON.stringify({
                        "name": (store.toJSON().pages[0].custom?.name ?? store.toJSON().pages[0].custom?.customId) ?? 'Default Value',
                        "customId": store.toJSON().pages[0].custom.customId,
                        "template": store.toJSON(),
                        "preview_img": await store.toDataURL(),
                        "is_video": isVideo
                    })
                });

                const data = await response.json();

                if (response.ok) {
                    // handle successful registration
                    setErrorMessage([]);
                    if (isSave) {
                        setStatusType('success')
                        setIsAlertOpen(true)
                        setStatusMessage('Template Saved')
                        setTemplateSaved(true);
                        setIsAutoSave(true);
                        trackEvent(EditorEvents.SAVED_TEMPLATE_SUCCESSFULLY.name)
                    }
                } else {
                    // handle registration error
                    console.error('Registration error:', data);
                    setStatusType('error')
                    setIsAlertOpen(true)
                    setStatusMessage('We ran into an errors while saving your template')
                    setErrorMessage(data)
                    trackEvent(EditorEvents.SAVED_TEMPLATE_ERROR.name)
                }
            } catch (error) {
                // handle network error
                console.error('Network error:', error);
            }
        } else {
            handleError(true)
            setStatusType('error')
            setIsAlertOpen(true)
            setStatusMessage('Please name your template before saving')
        }
    };

    useEffect(() => {
        if (templateName && isAutoSave) {
            store.on('change', () => {
                saveTemplate(false);
            });
        }
    }, [templateName, isAutoSave]);


    return (
        <div>
            <DownloadButton store={store}/>
            <Button
                onClick={()=>{
                    createNewPage()
                    trackEvent(EditorEvents.NEW_PAGE_BTN_CLICKED.name)
                }}
                style={{ marginLeft: '10px' }}
            >
                New Page
            </Button>
            <Button
                variant="contained"
                color="secondary"
                intent="primary"
                onClick={() => {
                    // save to DB
                    trackEvent(EditorEvents.SAVED_TEMPLATE_BTN_CLICKED.name)
                    saveTemplate(true);
                }}
            >
                Save
            </Button>
        </div>
    );
}
