import React, {useEffect, useRef, useState} from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import {useAuth} from "../providers/AuthProvider";
import ImageGalleryDialog from "./ImageGalleryDialog";
import Box from "@mui/material/Box";
import MastodonIcon from '@mui/icons-material/Public';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PermMediaSharpIcon from '@mui/icons-material/PermMediaSharp';
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {StaticDateTimePicker} from '@mui/x-date-pickers/StaticDateTimePicker';
import dayjs from "dayjs";
import {useStatus} from "../providers/MsgStatusProvider";
import styled from "@emotion/styled";
import {FormControl, FormGroup, InputLabel, Select, Tooltip} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import BlueSkyIcon from '@mui/icons-material/Cloud';
import ThreadsLogo from "./ThreadsLogo";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {useFeatureAccess} from "../hooks/useFeatureAccess";
import TikTokLogo from "./TikTokLogo";
import SpellCheckModal from './SpellCheckModal';
import SpellCheckBanner from './SpellCheckBanner';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {useAnalytics} from "../context/AnalyticsContext";
import {useTracking} from "../hooks/useTracking";
import {ComposePostEvents} from '../services/analytics/events';
import {TrackedButton} from "./TrackedButton";
import {trackEvent} from "../services/analytics/fathom";
import CircularProgress from '@mui/material/CircularProgress';
import TikTokPostContainer from './TikTokPostContainer';


const PLATFORM_LIMITS = {
    twitter: 280,
    facebook: 2200,
    instagram: 2200,
    linkedin: 3000,
    bluesky: 300,
    tiktok: 4000,
    threads: 500
};

const PlatformContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    overflow-y: scroll;
    height: ${props => props.hasSelectedAccounts ? '50vh' : '100%'};
`;

const PostBox = styled.div(({theme, disabled}) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
    ...(disabled && {
        backgroundColor: theme.palette.action.disabledBackground,
        opacity: 0.7,
    })
}));

const CharacterWarning = styled(Typography)`
    color: ${props => props.theme.palette.error.main};
    text-align: right;
    font-size: 0.875rem;
    margin-top: 4px;
`;


const Root = styled(Box)(({theme}) => ({
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: '1px solid ' + theme.palette.divider,
    // background: 'lightblue',
    // height: '75vh',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    }
}));

const PostImgPreview = styled.img`
    height: 180px;
    object-fit: initial;
    width: 200px;
`

const InputWrapper = styled.div(({theme}) => ({
    display: 'flex',
    gap: '24px',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '16px',
    }
}));

const ActionButtons = styled.div(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: theme.spacing(1),
        '& > *': {
            width: '100%',
        }
    }
}));

const AccountSelector = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        // Desktop view - keep existing horizontal layout
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
        // Mobile view
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    }
}));

const SelectedAccountsStack = styled(Stack)(({theme}) => ({
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
}));

const StyledAvatar = styled(Avatar)`
    filter: ${(props) => (props.isGrayscale ? 'grayscale(100%)' : 'none')};
    width: 40px;
    height: 40px;
    ${props => props.selected && `
    border: 3px solid #4CAF50;
    box-sizing: border-box;
  `}
`;
const AvatarWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

// const StyledAvatar = styled(Avatar)`
//   width: 40px;
//   height: 40px;
// `;

const IconOverlay = styled.div`
    position: absolute;
    bottom: -5px;
    right: -5px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
// const platforms = ["Facebook", "Twitter", "Instagram", "LinkedIn"];
const currentDate = new Date();
const formattedDate = currentDate.toISOString().split('T')[0];

const getPlatformIcon = (platform) => {
    switch (platform.toLowerCase()) {
        case 'twitter':
            return <TwitterIcon fontSize="small"/>;
        case 'linkedin':
            return <LinkedInIcon fontSize="small"/>;
        case 'linkedin_org':
            return <LinkedInIcon fontSize="small"/>;
        case 'instagram':
            return <InstagramIcon fontSize="small"/>;
        case 'threads':
            return <ThreadsLogo fontSize="small"/>;
        case 'bluesky':
            return <BlueSkyIcon fontSize="small"/>;
        case 'tiktok':
            return <TikTokLogo fontSize="small"/>;
        case 'mastodon':
            return <MastodonIcon fontSize="small"/>;
        case 'facebook':
            return <FacebookIcon fontSize="small"/>;
        default:
            return null;
    }
};

function DeleteIcon() {
    return null;
}

const AccountSelection = ({accounts, selectedAccounts, handleAccountChange}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const {trackEvent} = useAnalytics();
    const {trackWithError, trackWithSuccess} = useTracking();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (isMobile) {
        return (
            <>
                <TrackedButton
                    variant="outlined"
                    onClick={handleClick}
                    trackingData={ComposePostEvents.MOBILE_SELECT_SOCIAL_ACCOUNTS_BTN_CLICKED}
                    endIcon={<ExpandMoreIcon/>}
                    fullWidth
                >
                    Select Accounts
                </TrackedButton>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: '60vh',
                            width: '250px',
                        },
                    }}
                >
                    {accounts.map((account) => (
                        <MenuItem
                            key={account.user_id}
                            onClick={() => handleAccountChange(account.user_id, account.username, account.platform, account.account_img)}
                            selected={selectedAccounts.some(acc => acc.userId === account.user_id)}
                        >
                            <ListItemIcon>
                                <AvatarWrapper>
                                    <StyledAvatar
                                        alt={account.username}
                                        selected={selectedAccounts.some(acc => acc.userId === account.user_id)}
                                        style={{
                                            filter: selectedAccounts.some(acc => acc.userId === account.user_id)
                                                ? 'none'
                                                : 'grayscale(100%)'
                                        }}
                                        src={account.account_img}
                                    />
                                    <IconOverlay>
                                        {getPlatformIcon(account.platform)}
                                    </IconOverlay>
                                </AvatarWrapper>
                            </ListItemIcon>
                            <ListItemText primary={account.username} secondary={account.platform}/>
                        </MenuItem>
                    ))}
                </Menu>

                {selectedAccounts.length > 0 && (
                    <SelectedAccountsStack>
                        {selectedAccounts.map((account) => (
                            <Chip
                                key={account.userId}
                                avatar={
                                    <AvatarWrapper>

                                        <IconOverlay style={{transform: 'scale(0.7)'}}>
                                            {getPlatformIcon(account.platform)}
                                        </IconOverlay>
                                    </AvatarWrapper>
                                }
                                label={account.username}
                                onDelete={() => handleAccountChange(account.userId, account.username, account.platform, account.account_img)}
                                size="small"
                            />
                        ))}
                    </SelectedAccountsStack>
                )}
            </>
        );
    }

    // Desktop view - return existing horizontal layout
    return (
        <>
            {accounts.map((account) => (
                <Tooltip
                    title={`${account.platform.toLocaleUpperCase()}: ${account.username}`}
                    key={account.user_id}
                >
                    <IconButton
                        onClick={() => handleAccountChange(account.user_id, account.username, account.platform, account.account_img)}
                        size="large"
                    >
                        <AvatarWrapper>
                            <StyledAvatar
                                alt={account.username}
                                selected={selectedAccounts.some(acc => acc.userId === account.user_id)}
                                style={{
                                    filter: selectedAccounts.some(acc => acc.userId === account.user_id)
                                        ? 'none'
                                        : 'grayscale(100%)'
                                }}
                                src={account.account_img}
                            />
                            <IconOverlay>
                                {getPlatformIcon(account.platform)}
                            </IconOverlay>
                        </AvatarWrapper>
                    </IconButton>
                </Tooltip>
            ))}
        </>
    );
};

const ComposePost = () => {
    const auth = useAuth();
    const {canCreateMedia, canAccessImageGallery} = useFeatureAccess();
    const platforms = ["Twitter", "LinkedIn", "Instagram", "BlueSky", "Threads", "TikTok", "Mastodon"];
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    // const classes = useStyles();

    const [activePlatform, setActivePlatform] = useState(platforms[0]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [userPlatforms, setUserPlatforms] = useState([]);
    const [open, setOpen] = useState(false);
    const [postImg, setPostImg] = useState({
        url: '',
        type: 'png'
    });
    const [postDate, setPostDate] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [scheduledDate, setScheduledDate] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [imgAltText, setImgAltText] = useState(null);
    const {setStatusType, setStatusMessage, setIsAlertOpen} = useStatus();
    const [useCommonText, setUseCommonText] = useState(false);
    const [commonText, setCommonText] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const fileInputRef = useRef(null);

    const [showSpellCheckModal, setShowSpellCheckModal] = useState(false);
    const [skipSpellCheck, setSkipSpellCheck] = useState(false);
    const [spellCheckResults, setSpellCheckResults] = useState({});
    const [showSpellCheckBanner, setShowSpellCheckBanner] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [tiktokSettings, setTiktokSettings] = useState({
        privacy_level: 'SELF_ONLY',
        allow_comment: true,
        allow_duet: false,
        allow_stitch: false,
        is_commercial_content: false,
        is_branded_content: false
    });

    const checkSpelling = async (text) => {
        try {
            const response = await fetch('https://api.bing.microsoft.com/v7.0/spellcheck', {
                method: 'POST',
                headers: {
                    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SPELL_CHECK_KEY,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    text: text,
                    mode: 'proof'
                })
            });

            const data = await response.json();
            const results = {};

            data.flaggedTokens.forEach(token => {
                if (token.suggestions.length > 0) {
                    results[token.token] = token.suggestions[0].suggestion;
                }
            });

            return results;
        } catch (error) {
            console.error('Spell check error:', error);
            return {};
        }
    };


    useEffect(() => {
        return () => {
            // Cleanup object URLs when component unmounts
            if (postImg.url) {
                URL.revokeObjectURL(postImg.url);
            }
        };
    }, []);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        try {
            // Validate file type and size
            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];
            const maxSize = 10 * 1024 * 1024; // 10MB

            if (!validTypes.includes(file.type)) {
                setStatusType('error');
                setStatusMessage('Invalid file type. Please upload an image (JPG, PNG, GIF) or MP4 video.');
                setIsAlertOpen(true);
                return;
            }

            if (file.size > maxSize) {
                setStatusType('error');
                setStatusMessage('File size too large. Maximum size is 10MB.');
                setIsAlertOpen(true);
                return;
            }

            // Create preview URL and store file
            const fileUrl = URL.createObjectURL(file);
            setPostImg({
                url: fileUrl,
                type: file.type.split('/')[1]
            });
            setUploadedFile(file);
        } catch (error) {
            useTracking.trackWithError(ComposePostEvents.MEDIA_UPLOAD_FAILURE, error);
            console.error('Error uploading file:', error);
        }
    };

    // Initialize a state object with each platform as a key
    const [postTexts, setPostTexts] = useState(
        userPlatforms.reduce((acc, platform) => {
            acc[platform] = "";
            return acc;
        }, {})
    );

    // Add character count helper
    const getCharacterCount = (text) => {
        return text ? text.length : 0;
    };

    // Handle common text changes
    const handleCommonTextChange = (e) => {
        const newText = e.target.value;
        setCommonText(newText);

        if (useCommonText) {
            const updatedAccounts = selectedAccounts.map(account => ({
                ...account,
                message: newText
            }));
            setSelectedAccounts(updatedAccounts);
        }
    };

    // Check if any platform exceeds character limit
    const hasCharacterLimitExceeded = () => {
        return selectedAccounts.some(account =>
            getCharacterCount(account.message) > PLATFORM_LIMITS[account.platform.toLowerCase()]
        );
    };

    useEffect(() => {
        const platforms = auth.user[0].userprofile.social_accounts.map(account => account.platform);
        const uniquePlatforms = [...new Set(platforms)]; // Get unique platforms
        setUserPlatforms(uniquePlatforms);

        if (uniquePlatforms.length > 0) {
            setActivePlatform(uniquePlatforms[0]); // Set the first platform as the active platform
        }
    }, [auth]);

    const handleDateChange = (e) => {
        const dateValue = e.target.value;
        const formattedDate = dayjs(dateValue).format();
        setPostDate(formattedDate);
    };

    const handlePlatformClick = (platform) => {
        setActivePlatform(platform);
        setSelectedAccounts('');
    };

    const handleInputChange = (e, accountId) => {
        const updatedAccounts = selectedAccounts.map(account => {
            if (account.userId === accountId) {
                return {
                    ...account,
                    message: e.target.value
                };
            }
            return account;
        });
        setSelectedAccounts(updatedAccounts);

        // Update the message of the currently selected account
        if (currentAccount && currentAccount.userId === accountId) {
            setCurrentAccount({
                ...currentAccount,
                message: e.target.value
            });
        }
    };


    const handleImageAttach = () => {
        // Handle image attachment
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClearAll = () => {
        // Clear common text if it's being used
        setCommonText('');

        // Clear all individual account messages
        const clearedAccounts = selectedAccounts.map(account => ({
            ...account,
            message: ''
        }));
        setSelectedAccounts(clearedAccounts);

        // Optionally, you might want to clear the image as well
        // setPostImg({ url: '', type: 'png' });
        // setImgAltText('');
    };

    const handleSpellCheckFix = () => {
        setShowSpellCheckModal(false);
        setShowSpellCheckBanner(true);
    };

    const handlePostAnyway = () => {
        setSkipSpellCheck(true)
        setShowSpellCheckModal(false);
        setShowSpellCheckBanner(false);
        handlePostNow();

    };

    const handleConfirmSpellCheck = () => {
        setSpellCheckResults({});
        setShowSpellCheckBanner(false);
    };

    const clearForm = () => {
        // Clear common text
        setCommonText('');
        setUseCommonText(false);

        // Clear all account messages
        const clearedAccounts = selectedAccounts.map(account => ({
            ...account,
            message: ''
        }));
        setSelectedAccounts(clearedAccounts);

        // Clear media
        if (postImg.url) {
            URL.revokeObjectURL(postImg.url);
        }
        setPostImg({url: '', type: 'png'});
        setImgAltText('');
        setUploadedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        // Clear scheduled date if any
        setPostDate('');
        setShowCalendar(false);

        // Reset spell check related states
        setSkipSpellCheck(false);
        setSpellCheckResults({});
        setShowSpellCheckBanner(false);
    };

    const preparePostData = (account) => {
        const baseData = {
            message: useCommonText ? commonText : account.message,
            social_account_id: account.userId,
            media_url: postImg.url || null,
            alt_text: imgAltText || null
        };

        // Add TikTok-specific metadata
        if (account.platform === 'tiktok') {
            baseData.post_metadata = {
                ...tiktokSettings,
                social_account_id: account.userId
            };
        }

        return baseData;
    };

    const validateTikTokPost = (account) => {
        if (account.platform === 'tiktok') {
            if (tiktokSettings.is_branded_content && tiktokSettings.privacy_level === 'SELF_ONLY') {
                setStatusMessage('Branded content cannot be set to private visibility');
                setStatusType('error');
                return false;
            }
            
            if (!postImg.url || postImg.type !== 'mp4') {
                setStatusMessage('TikTok requires a video file');
                setStatusType('error');
                return false;
            }
        }
        return true;
    };

    const handlePostNow = async () => {
        setIsSubmitting(true);
        
        try {
            const activeAccounts = selectedAccounts.filter(account => account.message);
            const formDataArray = [];

            for (const account of activeAccounts) {
                // Skip TikTok accounts as they're handled by TikTokPostContainer
                // if (account.platform === 'tiktok') {
                //     continue;
                // }

                let formData = new FormData();
                formData.append('post_text', account.message);
                formData.append('is_scheduled_post', false);
                formData.append('social_account_id', account.userId);
                formData.append('platform', account.platform);
                formData.append('img_alt_text', imgAltText || '');
                // If we have an uploaded file, append it
                if (uploadedFile) {
                    // Convert file to base64
                    const base64 = await new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.readAsDataURL(uploadedFile);
                    });

                    formData.append('media_file', base64);
                    formData.append('media_type', postImg.type);
                } else {
                    formData.append('image_url', postImg.url);
                    formData.append('media_type', postImg.type);
                }
                formDataArray.push(Object.fromEntries(formData));
            }

            // Only proceed with API call if there are non-TikTok posts
            if (formDataArray.length > 0) {
                const url = process.env.REACT_APP_API_BASE_URL + 'post/posts/';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${auth.token}`
                    },
                    body: JSON.stringify(formDataArray)
                });

                const data = await response.json();
                if (data.success) {
                    setIsAlertOpen(true);
                    setStatusMessage(data.message);
                    setStatusType('success');
                    trackEvent(ComposePostEvents.POST_SUCCESS.name, {
                        platformCount: selectedAccounts.length,
                        hasMedia: !!postImg.url
                    });
                    clearForm();
                } else {
                    trackEvent(ComposePostEvents.POST_FAILURE.name);
                    setIsAlertOpen(true);
                    setStatusMessage(data.message);
                    setStatusType('error');
                }
            }
        } catch (error) {
            console.error('Error posting:', error);
            setIsAlertOpen(true);
            setStatusMessage('Error creating post');
            setStatusType('error');
        } finally {
            setIsSubmitting(false);
        }
    };


    const handleSchedulePost = async () => {
        setIsSubmitting(true);
        
        try {
            const activeAccounts = selectedAccounts.filter(account => account.message);
            const formDataArray = [];

            for (const account of activeAccounts) {

                let formData = {
                    post_text: account.message,
                    is_scheduled_post: true,
                    scheduled_post_date: postDate,
                    social_account_id: account.userId,
                    platform: account.platform,
                    image_url: postImg.url,
                    media_type: postImg.type,
                    img_alt_text: imgAltText
                }

                formDataArray.push(formData);
            }

            const url = process.env.REACT_APP_API_BASE_URL + 'post/tweet/scheduled/';
            const response = await fetch(url, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${auth.token}`
                },
                body: JSON.stringify(formDataArray)
            });

            const data = await response.json();
            if (data.success) {
                useTracking.trackWithSuccess(ComposePostEvents.SCHEDULE_POST_SUCCESS, {
                    platformCount: selectedAccounts.length,
                    scheduledDate: postDate,
                    hasMedia: !!postImg.url
                });
                setIsAlertOpen(true);
                setStatusMessage(data.message);
                setStatusType('success');
                clearForm();
            }
        } catch (error) {
            useTracking.trackWithError(ComposePostEvents.SCHEDULE_POST_FAILURE, error);
            console.error(error);
            setIsAlertOpen(true);
            setStatusMessage('Error scheduling post');
            setStatusType('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleAccountChange = (accountId, username, platform, account_img) => {
        const accountIndex = selectedAccounts.findIndex(account => account.userId === accountId);

        if (accountIndex > -1) {
            // Account exists, remove it
            const newSelectedAccounts = selectedAccounts.filter(account => account.userId !== accountId);

            setSelectedAccounts(newSelectedAccounts);
            // Unset the current account for editing if it's the one being removed
            if (currentAccount && currentAccount.userId === accountId) {
                setCurrentAccount(null);
            }

        } else {
            // Account doesn't exist, add it
            const newAccount = {
                userId: accountId,
                username,
                isActive: true,
                platform: platform,
                message: '',
                account_img: account_img
            };
            setSelectedAccounts([...selectedAccounts, newAccount]);
            // Set the current account for editing
            setCurrentAccount(newAccount);
        }

    };

    const isAnyMessageSet = () => {
        return selectedAccounts.some(account => account.message && account.message.trim() !== '');
    };

    function handleRemoveImage() {
        if (postImg.url) {
            URL.revokeObjectURL(postImg.url);
        }
        setPostImg({url: '', type: 'png'});
        setImgAltText('');
        setUploadedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }

    return (
        <Root>
            <Box>
                <div>
                    <InputWrapper>
                        {postImg.url &&
                            <div style={{width: '300px'}}>

                                {postImg.type === 'mp4' ? (
                                    <video width="100%" height="100%" controls>
                                        <source src={postImg.url}
                                                type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                ) : postImg.type === 'gif' ? (
                                    <img src={postImg.url} alt="Generated GIF"
                                         style={{width: '100%', height: '100%'}}/>
                                ) : (
                                    <img height="100%" width="100%"
                                         src={postImg.url} alt="Generated Image"/>
                                )}
                                <InputWrapper>
                                    <TextField
                                        label='Image Description'
                                        variant="outlined"
                                        fullWidth
                                        value={imgAltText ? imgAltText : ''}
                                        onChange={(e) => setImgAltText(e.target.value)}
                                    />
                                </InputWrapper>

                            </div>}
                        <div id='newPost' style={{width: '100%'}}>
                            <Box>
                                <AccountSelection
                                    accounts={auth.user[0].userprofile.social_accounts}
                                    selectedAccounts={selectedAccounts}
                                    handleAccountChange={handleAccountChange}
                                />
                                {selectedAccounts.length >= 2 && (
                                    <Box display="flex" justifyContent="space-between" sx={{mb: 2}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={useCommonText}
                                                    onClick={() => {
                                                        trackEvent(ComposePostEvents.SAME_TEXT_CHECKBOX.name)
                                                    }}
                                                    onChange={(e) => setUseCommonText(e.target.checked)}
                                                />
                                            }
                                            label="Use same text across all platforms"
                                        />
                                        <TrackedButton
                                            variant="outlined"
                                            onClick={handleClearAll}
                                            trackingData={ComposePostEvents.POST_CLEAR_ALL_CLICKED}
                                            disabled={!selectedAccounts.some(account => account.message) && !commonText}
                                            sx={{ml: {xs: 0, sm: 2}, mt: {xs: 1, sm: 0}}}
                                        >
                                            Clear All Text
                                        </TrackedButton>
                                    </Box>
                                )}
                            </Box>
                            <PlatformContainer hasSelectedAccounts={selectedAccounts.length > 0}>
                                {selectedAccounts.length > 0 ? (
                                    <>
                                        {selectedAccounts.map((account, index) => (
                                            <PostBox
                                                key={account.userId}
                                                disabled={useCommonText && index !== 0}
                                            >
                                                {showSpellCheckBanner && (
                                                    <Box sx={{
                                                        position: 'sticky',
                                                        bottom: 16,
                                                        left: 0,
                                                        right: 0,
                                                        zIndex: 1
                                                    }}>
                                                        <SpellCheckBanner
                                                            spellCheckResults={spellCheckResults}
                                                            onConfirmChanges={handleConfirmSpellCheck}
                                                        />
                                                    </Box>
                                                )}
                                                <Box display="flex" alignItems="center" gap={2} mb={2}>
                                                    <AvatarWrapper>
                                                        <StyledAvatar
                                                            alt={account.username}
                                                            selected={true}
                                                            src={account.account_img}
                                                        />
                                                        <IconOverlay>
                                                            {getPlatformIcon(account.platform)}
                                                        </IconOverlay>
                                                    </AvatarWrapper>
                                                    <Typography variant="subtitle1">
                                                        {account.username} ({account.platform})
                                                    </Typography>
                                                </Box>

                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={useCommonText && index !== 0}
                                                    value={useCommonText ? (index === 0 ? commonText : selectedAccounts[0].message) : account.message}
                                                    onChange={useCommonText && index === 0 ? handleCommonTextChange : (e) => handleInputChange(e, account.userId)}
                                                />

                                                {getCharacterCount(account.message) > PLATFORM_LIMITS[account.platform.toLowerCase()] - 20 && (
                                                    <CharacterWarning>
                                                        {getCharacterCount(account.message)}/{PLATFORM_LIMITS[account.platform.toLowerCase()]} characters
                                                        {getCharacterCount(account.message) > PLATFORM_LIMITS[account.platform.toLowerCase()] &&
                                                            ' - Text exceeds limit!'}
                                                    </CharacterWarning>
                                                )}

                                                {account.platform === 'tiktok' && (
                                                    <TikTokPostContainer
                                                        videoUrl={postImg.url}
                                                        socialAccountId={account.userId}
                                                        message={account.message}
                                                        onMessageChange={(e) => handleInputChange(e, account.userId)}
                                                        onPostComplete={(result) => {
                                                            if (result.success) {
                                                                setStatusMessage(result.message);
                                                                setStatusType('success');
                                                                setIsAlertOpen(true);
                                                                clearForm();
                                                                trackEvent(ComposePostEvents.POST_SUCCESS.name, {
                                                                    platform: 'tiktok',
                                                                    hasMedia: true
                                                                });
                                                            }
                                                        }}
                                                        onError={(error) => {
                                                            setStatusMessage(error);
                                                            setStatusType('error');
                                                            setIsAlertOpen(true);
                                                            trackEvent(ComposePostEvents.POST_FAILURE.name, {
                                                                platform: 'tiktok',
                                                                error
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </PostBox>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <PostBox>
                                            <Typography align="center" variant="body2">
                                                Please select an account
                                            </Typography>
                                        </PostBox>
                                    </>
                                )}

                            </PlatformContainer>
                        </div>


                    </InputWrapper>
                    <ActionButtons>
                        <Box sx={{display: 'flex', gap: 1, flexWrap: 'wrap'}}>
                            {canCreateMedia && (
                                <TrackedButton
                                    fullWidth={matches}
                                    color="primary"
                                    startIcon={<PermMediaSharpIcon/>}
                                    onClick={handleImageAttach}
                                    trackingData={ComposePostEvents.GENERATE_MEDIA_BTN_CLICKED}
                                >
                                    {postImg.url ? 'Edit Media' : 'Create Media'}
                                </TrackedButton>
                            )}
                            {postImg.url && (
                                <TrackedButton
                                    fullWidth={matches}
                                    color="error"
                                    startIcon={<DeleteIcon/>}
                                    onClick={handleRemoveImage}
                                    trackingData={ComposePostEvents.REMOVE_MEDIA_BTN_CLICKED}
                                >
                                    Remove Media
                                </TrackedButton>
                            )}
                            {!postImg.url && (
                                <TrackedButton
                                    fullWidth={matches}
                                    disabled={!!postImg.url}
                                    color="primary"
                                    startIcon={<UploadFileIcon/>}
                                    onClick={() => fileInputRef.current.click()}
                                    trackingData={ComposePostEvents.UPLOAD_MEDIA_BTN_CLICKED}
                                >
                                    Upload Media
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="image/*, video/mp4"
                                        style={{display: 'none'}}
                                        onChange={handleFileUpload}
                                    />
                                </TrackedButton>
                            )}

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            width: {xs: '100%', sm: 'auto'}
                        }}>
                            {!showCalendar ? (
                                <TrackedButton
                                    fullWidth={matches}
                                    variant="outlined"
                                    disabled={postDate !== '' || !isAnyMessageSet() || isSubmitting}
                                    startIcon={<CalendarTodayIcon/>}
                                    onClick={() => setShowCalendar(true)}
                                    trackingData={ComposePostEvents.SCHEDULE_BUTTON_CLICKED}
                                >
                                    Schedule Post
                                </TrackedButton>
                            ) : (
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TextField
                                            fullWidth
                                            id="datetime-local"
                                            type="datetime-local"
                                            defaultValue={dayjs(new Date())}
                                            label="Schedule Post"
                                            InputLabelProps={{shrink: true}}
                                            onChange={handleDateChange}
                                            disabled={isSubmitting}
                                            sx={{minWidth: {sm: 250}}}
                                        />
                                    </LocalizationProvider>
                                    <TrackedButton
                                        fullWidth={matches}
                                        variant="contained"
                                        disabled={!postDate || !isAnyMessageSet() || hasCharacterLimitExceeded() || isSubmitting}
                                        color="primary"
                                        onClick={handleSchedulePost}
                                        trackingData={ComposePostEvents.SCHEDULE_BUTTON_CLICKED}
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress size={24} color="inherit" />
                                        ) : (
                                            'Schedule'
                                        )}
                                    </TrackedButton>
                                </>
                            )}

                            <TrackedButton
                                fullWidth={matches}
                                variant="contained"
                                disabled={postDate !== '' || !isAnyMessageSet() || hasCharacterLimitExceeded() || isSubmitting}
                                color="primary"
                                onClick={showCalendar ? handleSchedulePost : handlePostNow}
                                trackingData={ComposePostEvents.POST_NOW_CLICKED}
                            >
                                {isSubmitting ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Post Now'
                                )}
                            </TrackedButton>
                        </Box>
                    </ActionButtons>
                </div>

                <ImageGalleryDialog open={open} onClose={handleClose} setPostImg={setPostImg} postImg={postImg}/>

            </Box>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Schedule Post
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDateTimePicker
                            onChange={date => {
                                const localDate = dayjs(date);
                                const localISOString = localDate.format();
                                setPostDate(localISOString);
                            }}

                            orientation="landscape"/>
                    </LocalizationProvider>
                </Box>
            </Modal>

            <SpellCheckModal
                open={showSpellCheckModal}
                onClose={() => setShowSpellCheckModal(false)}
                spellCheckResults={spellCheckResults}
                onFix={handleSpellCheckFix}
                onPostAnyway={handlePostAnyway}
            />
        </Root>
    );
};

export default ComposePost;
