import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import {Typography} from "@mui/material";
import {useNavigate} from "react-router";


export const getPricingPlans = async () => {
  const response = await fetch(process.env.REACT_APP_API_BASE_URL + "user/pricing-plans/");
  return response.json();
};

const PaidPage = () => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const plans = await getPricingPlans();
      setPricingPlans(plans);
    };

    fetchData();
    // Redirect to '/dashboard' after 7 seconds
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 3000);  // 7 seconds = 7000 milliseconds

    // Cleanup function to clear the timeout in case the component is unmounted before the timeout finishes
    return () => clearTimeout(timer);
  }, []);

  return (
      <Container maxWidth="md">
        <Typography align="center" variant="h2">Thanks for your payment!</Typography>
        <Typography align="center" variant="body1">You'll now be redirected to your homepage</Typography>
      </Container>
  );
};

export default PaidPage;
