import React, {useState, useEffect} from 'react';
import {
    Alert,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Select,
    Stack,
    Switch,
    Tooltip,
    Typography,
    CircularProgress,
    Link
} from '@mui/material';

export const TikTokPostForm = ({
                                   videoUrl,
                                   onSubmit,
                                   creatorInfo,
                                   isLoading,
                                   title,
                                   onTitleChange
                               }) => {
    const [formData, setFormData] = useState({
        privacy_level: '',
        is_commercial_content: false,
        is_your_brand: false,
        is_branded_content: false,
        allow_comment: false,
        allow_duet: false,
        allow_stitch: false
    });

    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [errors, setErrors] = useState({});

    // Validate commercial content settings
    const validateCommercialContent = () => {
        if (formData.is_commercial_content) {
            if (!formData.is_branded_content && !formData.is_your_brand) {
                return "Please select at least one commercial content type";
            }
            if (formData.is_branded_content && formData.privacy_level === 'SELF_ONLY') {
                return "Branded content cannot be set to private visibility";
            }
        }
        return null;
    };

    // Get terms text based on commercial content settings
    const getTermsText = () => {
        const links = [];
        
        // Always include Music Usage Confirmation
        links.push({
            text: "TikTok's Music Usage Confirmation",
            url: "https://www.tiktok.com/legal/music-usage-confirmation"
        });
        
        // Add Branded Content Policy if needed
        if (formData.is_commercial_content && formData.is_branded_content) {
            links.unshift({
                text: "TikTok's Branded Content Policy",
                url: "https://www.tiktok.com/legal/branded-content-policy"
            });
        }
        
        return (
            <Typography component="span">
                By posting, you agree to{' '}
                {links.map((link, i) => (
                    <React.Fragment key={i}>
                        {i > 0 && " and "}
                        <Link
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {link.text}
                        </Link>
                    </React.Fragment>
                ))}
            </Typography>
        );
    };

    const handleSubmit = async () => {
        const error = validateCommercialContent();
        if (error) {
            setErrors({commercial: error});
            return;
        }

        if (!agreedToTerms) {
            setErrors({terms: "You must agree to the terms"});
            return;
        }

        await onSubmit({
            ...formData,
            title
        });
    };

    // Add new alert for posting restrictions
    const renderRestrictionAlert = () => {
        if (!creatorInfo) return null;

        if (creatorInfo.restriction_message) {
            return (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    {creatorInfo.restriction_message}
                </Alert>
            );
        }

        if (creatorInfo.remaining_posts === 0) {
            return (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    You have reached your daily posting limit
                </Alert>
            );
        }

        return null;
    };

    // Add posting limits info
    const renderPostingLimits = () => {
        if (!creatorInfo) return null;

        return (
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Posts today: {creatorInfo.posts_today} / {creatorInfo.max_posts_per_day}
            </Typography>
        );
    };

    // Update privacy level validation
    useEffect(() => {
        if (creatorInfo?.requires_private_posting) {
            setFormData(prev => ({
                ...prev,
                privacy_level: 'SELF_ONLY'
            }));
        }
    }, [creatorInfo]);

    const getCommercialContentLabel = () => {
        if (formData.is_your_brand && formData.is_branded_content) {
            return "Your photo/video will be labeled as 'Paid partnership'";
        }
        if (formData.is_your_brand) {
            return "Your photo/video will be labeled as 'Promotional content'";
        }
        if (formData.is_branded_content) {
            return "Your photo/video will be labeled as 'Paid partnership'";
        }
        return '';
    };

    const handleCommercialToggle = (event) => {
        const isCommercial = event.target.checked;
        setFormData(prev => ({
            ...prev,
            is_commercial_content: isCommercial,
            // Reset commercial options when toggling off
            is_your_brand: isCommercial ? prev.is_your_brand : false,
            is_branded_content: isCommercial ? prev.is_branded_content : false,
            // Auto-adjust privacy if needed
            privacy_level: isCommercial && prev.is_branded_content && prev.privacy_level === 'SELF_ONLY' 
                ? 'PUBLIC_TO_EVERYONE' 
                : prev.privacy_level
        }));
    };

    const handleBrandedContentChange = (event) => {
        const isBranded = event.target.checked;
        setFormData(prev => ({
            ...prev,
            is_branded_content: isBranded,
            // Auto-adjust privacy level if setting to branded content
            privacy_level: isBranded && prev.privacy_level === 'SELF_ONLY' 
                ? 'PUBLIC_TO_EVERYONE' 
                : prev.privacy_level
        }));
    };

    // Determine if private option should be disabled
    const isPrivateDisabled = formData.is_commercial_content && formData.is_branded_content;

    // Reset commercial content settings when privacy level changes
    useEffect(() => {
        if (formData.privacy_level !== 'PUBLIC_TO_EVERYONE') {
            setFormData(prev => ({
                ...prev,
                is_commercial_content: false,
                is_your_brand: false,
                is_branded_content: false
            }));
        }
    }, [formData.privacy_level]);

    const handlePrivacyChange = (event) => {
        const newPrivacyLevel = event.target.value;
        setFormData(prev => ({
            ...prev,
            privacy_level: newPrivacyLevel,
            // Reset commercial content if not public
            ...(newPrivacyLevel !== 'PUBLIC_TO_EVERYONE' && {
                is_commercial_content: false,
                is_your_brand: false,
                is_branded_content: false
            })
        }));
    };

    const showCommercialContent = formData.privacy_level === 'PUBLIC_TO_EVERYONE';

    return (
        <Box>
            {renderRestrictionAlert()}
            {renderPostingLimits()}
            
            {/* Privacy Level Selection */}
            <FormControl fullWidth required error={!formData.privacy_level} sx={{mb: 2}}>
                <Typography variant="subtitle2" gutterBottom>
                    Privacy Level
                </Typography>
                <Select
                    value={formData.privacy_level}
                    onChange={handlePrivacyChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>Select privacy level</MenuItem>
                    {creatorInfo?.privacy_level_options?.map(option => (
                        <MenuItem
                            key={option}
                            value={option}
                            disabled={formData.is_branded_content && option === 'SELF_ONLY'}
                        >
                            {option.toLowerCase().replace(/_/g, ' ')}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Required</FormHelperText>
            </FormControl>

            {/* Interaction Settings */}
            <Stack spacing={2}>
                <Typography variant="subtitle2">Interaction Settings</Typography>
                <Box display="flex" justifyContent="space-between">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.allow_comment}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    allow_comment: e.target.checked
                                }))}
                                disabled={creatorInfo?.disabled_comment}
                            />
                        }
                        label="Allow Comments"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.allow_duet}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    allow_duet: e.target.checked
                                }))}
                                disabled={creatorInfo?.disabled_duet}
                            />
                        }
                        label="Allow Duets"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.allow_stitch}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    allow_stitch: e.target.checked
                                }))}
                                disabled={creatorInfo?.disabled_stitch}
                            />
                        }
                        label="Allow Stitches"
                    />
                </Box>

            </Stack>

            {/* Commercial Content Settings */}
            {showCommercialContent && (
                <>
                    <FormControl fullWidth margin="normal">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.is_commercial_content}
                                    onChange={handleCommercialToggle}
                                />
                            }
                            label="This content promotes a brand, product, or service"
                        />
                    </FormControl>

                    {formData.is_commercial_content && (
                        <Box sx={{ ml: 3, mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.is_your_brand}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            is_your_brand: e.target.checked
                                        }))}
                                    />
                                }
                                label="Your brand"
                                helperText="you are promoting yourself or your own business. This video will be classiffied as Brand Organic."
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.is_branded_content}
                                        onChange={handleBrandedContentChange}
                                    />
                                }
                                label="Branded content"
                                helperText="You are promotiing another bran or a thrid party. This video will be classified as Branded Content."
                            />
                            {formData.is_commercial_content && 
                             !formData.is_your_brand && 
                             !formData.is_branded_content && (
                                <FormHelperText error>
                                    Please select at least one option
                                </FormHelperText>
                            )}
                            {getCommercialContentLabel() && (
                                <Typography variant="caption" color="textSecondary">
                                    {getCommercialContentLabel()}
                                </Typography>
                            )}
                        </Box>
                    )}
                </>
            )}

            {/* Terms Agreement */}
            <Box sx={{mt: 3}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={agreedToTerms}
                            onChange={(e) => setAgreedToTerms(e.target.checked)}
                        />
                    }
                    label={getTermsText()}
                />
                {errors.terms && (
                    <FormHelperText error>{errors.terms}</FormHelperText>
                )}
            </Box>
        </Box>
    );
};