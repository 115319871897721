import React, { useState } from 'react';
import { Box, Alert } from '@mui/material';
import { TikTokPostForm } from "./TikTokPostForm";
import { postToTikTok } from '../api';
import {useCreatorInfo} from "../hooks/useCreatorInfo";

const TikTokPostContainer = ({
  videoUrl,
  socialAccountId,
  onPostComplete,
  onError,
  message,
  onMessageChange
}) => {
  const [isPosting, setIsPosting] = useState(false);
  const { creatorInfo, isLoading: isLoadingCreatorInfo, error: creatorInfoError } = useCreatorInfo(socialAccountId);

  const handleSubmit = async (formData) => {
    try {
      setIsPosting(true);
      const result = await postToTikTok({
        videoUrl,
        socialAccountId,
        ...formData
      });

      if (result.success) {
        onPostComplete(result);
      } else {
        onError(result.message);
      }
    } catch (error) {
      onError(error instanceof Error ? error.message : 'Failed to post to TikTok');
    } finally {
      setIsPosting(false);
    }
  };

  if (creatorInfoError) {
    return (
      <Alert severity="error">
        Failed to load TikTok account information. Please try again later.
      </Alert>
    );
  }

  return (
    <Box>
      <TikTokPostForm
        videoUrl={videoUrl}
        onSubmit={handleSubmit}
        creatorInfo={creatorInfo}
        isLoading={isPosting || isLoadingCreatorInfo}
        title={message}
        onTitleChange={onMessageChange}
      />
    </Box>
  );
};

export default TikTokPostContainer;
